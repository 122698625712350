import React from 'react';
import { graphql, Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from './../components/shared/Layout';
import Sidebar from './../components/article/Sidebar';
import { Container } from './../elements/SharedElements';
import { Wrapper, ArticleContainer } from './../elements/ArticleElements';

export const article = (graphql`
    query contentfulArticle($slug: String) {
            contentfulArticle(slug: {
            eq: $slug
        }) {
            title
            section
            slug
            featuredImage {
                file {
                    url
                }
            }
            featuredArticle
            date(formatString: "MMMM D, YYYY")
            body {
                json
            }
            tags
            synopsis
        }
    }
`)

const Article = (props) => {

    const options = {
        renderNode: {
          "embedded-asset-block": (node) => {
            const src = node.data.target.fields.file['en-US'].url;
            const alt = node.data.target.fields.title['en-US'];

            console.log(alt);

            if(alt.toLowerCase().includes("video")){
                return <video src={src} controls></video>
            }else{
                return <img src={src} alt={alt} />
            }
          }
        }
      }

    return (
        <Layout title={props.data.contentfulArticle.title} description={props.data.contentfulArticle.synopsis} tags={props.data.contentfulArticle.tags}>
            <Container>
                <Wrapper>
                    <ArticleContainer>
                        { props.data.contentfulArticle.featuredArticle && <span className="featured">Featured</span>}
                        <h1>{props.data.contentfulArticle.title}</h1>
                        <div className="article-header">
                            <span className="date">{props.data.contentfulArticle.date}</span>
                            <Link  className="btn" to={props.data.contentfulArticle.section.toLowerCase()}>{props.data.contentfulArticle.section}</Link>
                        </div>
                        <img src={props.data.contentfulArticle.featuredImage.file.url} alt="Featured Article"/>
                        <div className="body">
                            {documentToReactComponents(props.data.contentfulArticle.body.json, options)}
                        </div>
                    </ArticleContainer>
                    <Sidebar slug={props.data.contentfulArticle.slug}>
                    
                    </Sidebar>
                </Wrapper>

            </Container>
        </Layout>
    )

}

export default Article;