import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Side } from '../../elements/ArticleElements';
import ArticleList from './ArticleList';

const Sidebar = (props) => {

    const data = useStaticQuery(graphql`

        query sideBarArticles {

            allContentfulArticle (
                sort: {
                    fields: date
                    order: DESC
                }
            ){
                edges {
                    node {
                        title,
                        featuredImage {
                            file {
                                url
                            }
                        }
                        slug
                        date(formatString: "MMMM D, YYYY")
                        section
                    }
                }
            }

        }

    `)

    const filtered = data.allContentfulArticle.edges.filter((edge) => {

        return edge.node.slug !== props.slug

    })

    return (
        <Side>
            <h2>Latest Reviews</h2>
            {
                filtered.map((edge) => {
                    return <ArticleList key={edge.node.slug} {...edge.node}/>
                })
            }
        </Side>
    )

}

export default Sidebar;