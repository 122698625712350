import React from 'react';
import { Link } from 'gatsby';
import { ArticleWrap } from './../../elements/ArticleElements';

const ArticleList = ({ featuredImage, title, slug, section, date}) => {

     slug = `${section.toLowerCase()}/${slug}`;

    return (
        <ArticleWrap>
            <Link className="image" to={slug} style={{backgroundImage: `url(${featuredImage.file.url})`}}>
            </Link>
            <div className="content">
                <Link to={slug}><h4>{title}</h4></Link>
                <span>{date}</span>
            </div>
        </ArticleWrap>
    )

}

export default ArticleList;