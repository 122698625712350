import styled from 'styled-components';

export const Wrapper = styled.section`
    padding: 2rem 1rem;
    display: flex;

    @media screen and (max-width: 768px){
        flex-direction: column;
        padding: 0;
    }
`

export const ArticleWrap = styled.div`

    display: flex;
    margin-bottom: 1rem;

    .image {
        flex: 2;
        margin-right: 0.5rem;
        background-size: cover;
        background-position: center center;
        height: 5rem;

        @media screen and (max-width: 768px){
            flex: 1;
        }

    }

    .content {
        flex: 3;

        h4 {
            color: ${ props => props.theme.primaryColor};
            font-size: 1.2rem;
            word-break: break-word;
        }

        span {
            font-size: 0.8rem;
            color: ${ props => props.theme.darkGreyColor};
        }

    }

`

export const Side = styled.div`
    padding: 2rem;
    flex: 1;

    h2 {
        color: ${ props => props.theme.darkGreyColor};
        font-size: 2.2rem;
        margin-bottom: 0.8rem;
    }
`

export const ArticleContainer = styled.div`
    flex: 2;
    padding: 2rem;

    @media screen and (max-width: 768px){
        padding-bottom: 0;
    }

    h1 {
        color: ${ props => props.theme.darkGreyColor};
        font-size: 2.2rem;
        margin-bottom: 0.8rem;
    }

    .featured {
        color: ${ props => props.theme.primaryColor};
        font-weight: 900;
    }

    img {
        width: 100%;
    }

    .article-header {

        margin-bottom: 1rem;

        .date {
            color: ${ props => props.theme.darkGreyColor};
            padding-right: 1rem;
        }

        .btn {
            padding: 0.1rem 0.8rem 0.2rem;
            background-color: ${ props => props.theme.primaryColor};
            color: ${ props => props.theme.secondaryColor};
            border-radius: 0.5rem;
            font-size: 0.8rem;
        }

    }

    .body {

        p {
            margin-bottom: 1rem;

        }

        ul {
            padding-left: 3rem;
        }

        i {
            font-weight: 100;
        }

        a {
            color: ${ props => props.theme.primaryColor};
            text-decoration: underline;
        }

        video {

            width: 100%;

        }
    }
`